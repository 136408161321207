import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ marketData }) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const loginPageUrl = process.env.REACT_APP_LOGIN_PAGE_URL;

  const [isScrolled, setIsScrolled] = useState(false);


  // Extract colors from marketData
  const headerColor = marketData?.headerColor || '#ffffff'; 

  // Background image style
  const backgroundImage = {
    backgroundImage: marketData && marketData.bannerImage
      ? `url(${staticImagePath}/${marketData.bannerImage})`
      : '',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // height: '100vh', // You can include height if needed
  };

  
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Change threshold as needed
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
// Inline style for header color
const headerStyle = {
  backgroundColor: isScrolled ? headerColor : 'transparent',
  transition: 'background-color 0.3s ease' // Smooth transition effect
};
  return (
    <div>
      <header >
        {/* Header Start */}
        <div className="header-area header-transparent">
          <div className="main-header">
            <div className="header-bottom header-sticky" style={headerStyle}>
              <div className="container-fluid">
                <div className="row align-items-center">
                  {/* Logo */}
                  <div className="col-xl-12 col-lg-12">
                    <div className="logo d-flex align-items-center justify-content-center py-2">
                      <a href="index.html" className="tittle-logo">
                        {marketData?.mainTitle}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="menu-wrapper d-flex align-items-center justify-content-center">
                      {/* Main-menu */}
                      <div className="main-menu d-none d-lg-block">
                        <nav>
                          <ul id="navigation">
                            <li className="active">
                              <Link to={`/?id=${marketData?.market}`}>
                                Home
                              </Link>
                            </li>
                            <li>
                              <Link to={`/meetourvendor?id=${marketData?.market}`}>
                                Meet our Vendors
                              </Link>
                            </li>
                            <li>
                              <Link to={`/marketmap?id=${marketData?.market}`}>
                                Market Maps
                              </Link>
                            </li>
                            <li>
                              <Link to={`/apply-here?id=${marketData?.market}`}>
                                Apply Here
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      {/* Header-btn */}
                      <div className="header-right-btn d-none d-lg-block ml-65">
                        

                        <Link 
                          to={loginPageUrl} 
                          className="border-btn" 
                          target="_blank" // Opens in a new tab or window
                          rel="noopener noreferrer" // Security measure when using target="_blank"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Mobile Menu */}
                  <div className="col-12">
                    <div className="mobile_menu d-block d-lg-none" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header End */}
      </header>
    </div>
  );
};

export default Header;
